import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  UPLOAD_IMAGE,
} from "../../../lib/api/admin";
import Button from "../../shared/Button/Button";
import Input from "../../shared/Input/Input";
import Modal from "../../shared/Modal/Modal";
import Select from "../../shared/Select/Select";
import UploadImage from "../../shared/UploadImage/UploadImage";
import "./AddEditCategoryModal.css";
import axios from "axios";
import {
  BUCKET_URL,
  S3_PREASSIGNED_POST_URL,
  CLOUDFRONT_URL,
} from "../../../config";

export default function AddEditCategoryModal({
  children,
  subCat,
  edit,
  category,
}) {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync: uploadImageMutation } = useMutation(UPLOAD_IMAGE);
  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: useMemo(() => category, [category]),
  });
  const { mutateAsync: createCategory } = useMutation(CREATE_CATEGORY);
  const { mutateAsync: updateCategory } = useMutation(UPDATE_CATEGORY);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  async function uploadImage(image, type) {
    return image;
  }

  const handleNewCategoryCreate = useCallback(
    async (values) => {
      try {
        if (subCat) {
          // delete values['logo'];
          values["parent"] = subCat;
        }
        await createCategory(values);
        await queryClient.refetchQueries(
          subCat ? "GET_SUB_CATEGORIES" : "GET_CATEGORIES"
        );
        toast.success(
          `${subCat ? "Sub Category" : "Category"} created successfully`
        );
        setOpen(false);
        reset();
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
    },
    [createCategory, queryClient, reset, subCat]
  );

  const handleNewCategoryUpdate = useCallback(
    async (values) => {
      try {
        delete values["_id"];
        delete values["__v"];
        delete values["parent"];
        delete values["jobs_posted"];
        delete values["createdAt"];
        delete values["updatedAt"];
        if (subCat) {
          values["parent"] = subCat;
        }
        if (values.logo === null) {
          delete values["logo"];
        }
        await updateCategory({ cat_id: category?._id, values });
        await queryClient.refetchQueries(
          subCat ? "GET_SUB_CATEGORIES" : "GET_CATEGORIES"
        );
        toast.success(
          `${subCat ? "Sub Category" : "Category"} updated successfully`
        );
        setOpen(false);
        reset();
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.msg || err?.message);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [category, queryClient, reset, subCat, updateCategory]
  );

  useEffect(() => {
    reset(category);
  }, [category, reset]);

  return (
    <Fragment>
      {children &&
        React.cloneElement(children, {
          onClick: (e) => {
            e.stopPropagation();
            setOpen(true);
          },
        })}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        title={`${!edit ? "Add New" : "Update"} ${
          subCat ? "Sub-Category" : "Category"
        }`}
      >
        <form
          onSubmit={handleSubmit(
            category ? handleNewCategoryUpdate : handleNewCategoryCreate
          )}
          className="addEditCategoryModal"
          key={`AddEditCategoryModal__${open}`}
        >
          {
            <UploadImage
              label="Upload Category Icon"
              image={category?.logo}
              onChange={(image) => setValue("logo", image)}
            />
          }

          <Input label="Category Name" {...register("name")} />
          <Input label="Category Bangla Name" {...register("name_bn")} />
          <Input
            type="number"
            label="Max Client Charge"
            {...register("max_charge_client", { valueAsNumber: true })}
          />
          <Input
            type="number"
            label="Min Client Charge"
            {...register("min_charge_client", { valueAsNumber: true })}
          />
          <Input
            type="number"
            label="Max Provider Charge"
            {...register("max_charge_provider", { valueAsNumber: true })}
          />
          <Input
            type="number"
            label="Min Provider Charge"
            {...register("min_charge_provider", { valueAsNumber: true })}
          />
          <Input
            type="number"
            label="Serial"
            {...register("serial", { valueAsNumber: true })}
          />
          <label className="d-block mt-3">
            <strong>Delivery Status</strong>
            <Select
              variant="ghost_white"
              defaultValue={
                category
                  ? category?.print && category?.delivery
                    ? "both"
                    : !category?.print && category?.delivery
                    ? "delivery"
                    : !category?.print && !category?.delivery
                    ? "none"
                    : ""
                  : ""
              }
              onChange={({ target }) => {
                if (target.value === "delivery") {
                  setValue("delivery", true);
                } else if (target.value === "none") {
                  setValue("delivery", false);
                }
              }}
            >
              <option value="">Select</option>
              <option value="delivery">Delivery Mandatory</option>
              <option value="none">Delivery Not Mandatory</option>
            </Select>
          </label>

          <br />
          <Button type="submit" variant="primary">
            Save
          </Button>
        </form>
      </Modal>
    </Fragment>
  );
}

import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import ImageUploader from "../../../components/util/ImageUploader";
import { AuthContext } from "../../../context/auth-context";
import { useHttpClient } from "../../../hooks/http-hook";
import LoadingSpinner from "../../util/LoadingSpinner";
import BasicInfo from "./BasicInfo";
import "./Edit_Profile.css";
import Security from "./Security";
import PaymentInfo from "./PaymentInfo";
import MoreInfo from "./MoreInfo";
import axios from "axios";
import { BUCKET_URL,CLOUDFRONT_URL,S3_PREASSIGNED_POST_URL } from "../../../config";
const image = "https://via.placeholder.com/150/92c952";

const EditProfileNew = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [load, setLoad] = useState(false);
  const auth = useContext(AuthContext);
  const { sendRequest, clearError } = useHttpClient();
  const [profilePic, setProfilePic] = useState(null);
  const [files, setFiles] = useState({});
  const [filesUrl, setFilesUrl] = useState("");
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  useEffect(() => {
    setUpdateUser();
  }, [auth.user]);

  const setUpdateUser = () => {
    if (auth.user) {
      setProfilePic(auth.user.profile_pic);
    }
  };

  useEffect(() => {
    fileUpload();
  }, [files]);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result;
        resolve(base64String);
      };

      reader.onerror = function (error) {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const fileUpload = async () => {
    try {
      if (files.length > 0) {

        for (const file of files) {
          const data = await axios.get(
            `${S3_PREASSIGNED_POST_URL}?size=${globalSettings?.max_file_size}&fileExtension=${file.name.split('.').pop()}`
          );
    
          const formData = new FormData();
          Object.entries(data.data[0].fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", file);
    
          const uploadResponse = await axios.post(BUCKET_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          
          setFilesUrl([CLOUDFRONT_URL+ `${data.data[0].fields.Key}`,...filesUrl]);
        }
      }
    } catch (err) {
      toast.error(`Max file upload size is ${globalSettings?.max_file_size}MB`);
    }
  };

  return (
    <div className="container">
      {load && <LoadingSpinner asOverlay />}
      <h3 className="fw-bold mb-3">
        {" "}
        {auth.language === "en" ? "Edit Profile" : "বৃত্তান্ত সম্পাদনা"}
      </h3>
      <div className="form-section">
        {profilePic ? (
          <img className="profileimg" src={profilePic} alt="" />
        ) : (
          <img className="profileimg" src={image} alt="test_image" />
        )}

        <h4 className="my-3 fw-bold">
          {" "}
          {auth.language === "en" ? "Profile Picture" : "বৃত্তান্ত ছবি"}{" "}
        </h4>

        <ImageUploader
          profile
          fieldName="image"
          url="/user/change_profile_picture"
          title={
            auth.language === "en" ? "Upload New Photo" : "নতুন ছবি আপলোড করুন"
          }
        />
      </div>
      <Tabs defaultActiveKey="basic">
        <Tab
          eventKey="basic"
          title={auth.language === "en" ? "Basic Info" : "সাধারণ তথ্য"}
          className=" m-0"
        >
          <BasicInfo />
        </Tab>
        <Tab
          eventKey="payment"
          title={auth.language === "en" ? "Payment Info" : "লেনদেনের তথ্য"}
          className=" m-0"
        >
          <PaymentInfo />
        </Tab>
        <Tab
          eventKey="security"
          title={auth.language === "en" ? "Security" : "নিরাপত্তা"}
          className=" m-0"
        >
          <Security />
        </Tab>

        <Tab
          eventKey="more"
          title={auth.language === "en" ? "More Info" : "অন্যান্য"}
          className=" m-0"
        >
          <MoreInfo />
        </Tab>
      </Tabs>
    </div>
  );
};

export default EditProfileNew;

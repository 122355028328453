import React, { useCallback, useMemo, useState, useEffect } from "react";
import "./UploadImage.css";
import axios from "axios";
import { BUCKET_URL,S3_PREASSIGNED_POST_URL,CLOUDFRONT_URL } from "../../../config";
import { toast } from "react-toastify";

export default function UploadImage({
  label,
  image: propsImage,
  onChange,
  btnText,
  btnTextClass,
}) {
  const [image, setImage] = useState(propsImage || null);
  const [globalSettings, setGlobalSettings] = useState(null);

  useEffect(() => {
    const getGlobals = async () => {
      try {
        const token = localStorage.getItem("auth_token");
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get_globals",
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        setGlobalSettings(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getGlobals();
  }, []);

  const handleChange = useCallback(async ({ target }) => {
    const file = target.files[0];

    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const data = await axios.get(
          S3_PREASSIGNED_POST_URL+`?size=${globalSettings?.max_file_size}&fileExtension=${file.name.split('.').pop()}`
        );

        const formData = new FormData();
        Object.entries(data.data[0].fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        const uploadResponse = await axios.post(BUCKET_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        onChange(CLOUDFRONT_URL+`${data.data[0].fields.Key}`, "logo");
        setImage(CLOUDFRONT_URL+`${data.data[0].fields.Key}`);
      } catch (error) {
        toast.error(`Max file upload size is ${globalSettings?.max_file_size}MB`);
      }
    };

    reader.readAsDataURL(file);
  }, [onChange, globalSettings]);

  const labelStr = useMemo(
    () => String(label).toLowerCase().split(" ").join("_"),
    [label]
  );

  return (
    <div className="uploadImage__image">
      <img src={image} alt="" />
      <strong>{label}</strong>
      <input
        id={`uploadImage_${labelStr}`}
        type="file"
        hidden
        onChange={handleChange}
      />
      <label htmlFor={`uploadImage_${labelStr}`} className={btnTextClass}>
        {btnText || "Upload"}
      </label>
    </div>
  );
}

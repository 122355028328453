import React, { useState, useEffect, useContext } from "react";
import "./Deals.css";
import Pagination from "../../Pagination";
import ShowDeals from "./ShowDeals";
import { useHttpClient } from "../../../hooks/http-hook";
import SuccessModal from "../../../components/util/SuccessModal";
import { AuthContext } from "../../../context/auth-context";

const Deals = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const auth = useContext(AuthContext);

  const [showDeals, setShowDeals] = useState();
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [dealOption, setDealOption] = useState("providers");
  const [deals, setDeals] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { isLoading, sendRequest } = useHttpClient();
  const [userType, setUserType] = useState("all");

  useEffect(() => {
    if (auth.user) {
      setCurrentPage(1);
      fetchDeals();
    }
  }, [auth.user, refresh, dealOption, userType]);

  const fetchDeals = async () => {
    try {
      let url =
        process.env.REACT_APP_BACKEND_URL +
        `/user/my_deals?page=${currentPage}`;
      if (userType !== "all") {
        url += `&type=${userType}`;
      }
      const responseData = await sendRequest(url);
      setDeals(responseData);
      console.log("data", responseData);
    } catch (err) {
      // Handle errors here
    }
  };

  // for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleUserTypeChange = (type) => {
    setUserType(type === userType ? "all" : type);
  };

  return (
    <div className="px-5 deals_page">
      <div style={{ marginBottom: "-20px"}}>
        <h1 className="mb-2">My Deals</h1>
        <div className="mb-8" style={{ display: "flex", gap: "20px", marginBottom: "50px" }}>
          <button
            className={`userTypeButton ${userType === "all" ? "active" : ""}`}
            onClick={() => handleUserTypeChange("all")}
          >
            All
          </button>
          <button
            className={`userTypeButton ${userType === "provider" ? "active" : ""}`}
            onClick={() => handleUserTypeChange("provider")}
          >
            With Providers
          </button>
          <button
            className={`userTypeButton ${userType === "client" ? "active" : ""}`}
            onClick={() => handleUserTypeChange("client")}
          >
            With Clients
          </button>
        </div>
      </div>
      <ShowDeals deals={deals?.data || []} loading={loading} client={true} />

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={deals.length || 0}
        paginate={paginate}
        pageNumber={currentPage}
        style={{ backgroundColor: "#2A41E8" }}
      />
    </div>
  );
};

export default Deals;

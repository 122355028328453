import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/auth-context";
import "./TopProviderCard.css";
import { Margin } from "@material-ui/icons";
import { displayName } from "react-quill";

// TODO: Provider location and position not available in the response

const containerStyle = {
  backgroundColor: "var(--WhiteBG, #FFF)",
  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: "4px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

const profilePicStyle = {
  borderRadius: "4px",
  objectFit: "cover",
};

const headingStyle = {
  color: "var(--grey_one, #333)",
  fontFamily: "Nunito",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  margin: "0",
};

const ProfileContainerStyle = {
  display: "flex",
  alignItems: "start",
  gap: "10px",
};

const StatContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "14px",
};

const TextStyle = {
  color: "var(--grey_two, #666)",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "0",
  paddingRight: "7px",
};

const ValueStyle = {
  color: "var(--grey_one, #333)",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  margin: "0",
  paddingRight: "5px",
};

const taglineStyle = {
  color: "var(--grey_two, #666)",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

const buttonStyle = {
  width: "100%",
  borderRadius: "4px",
  padding: "10px",
  backgroundColor: "#2A41E8",
  border: "none",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const buttonTextStyle = {
  color: "var(--WhiteBG, #FFF)",
  textAlign: "center",
  fontFamily: "Nunito",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
};

const ProviderCardNew = ({ provider, noProvider }) => {
  const auth = useContext(AuthContext);

  return (
    <div style={containerStyle}>
      <div style={ProfileContainerStyle}>
        <img
          src={
            noProvider
              ? provider?.profile_pic || "/assets/top-img.png"
              : provider?.provider_id?.profile_pic || "/assets/top-img.png"
          }
          alt="profile pic"
          width="110px"
          height="110px"
          style={profilePicStyle}
        />
        <div style={StatContainerStyle}>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            <p style={TextStyle}>
              {auth.language === "en" ? "Success Ratio" : "সাফল্যের অনুপাত"}{" "}
            </p>
            <p style={ValueStyle}>
              {provider?.success_ratio ? Number(provider?.success_ratio) : "0"}
              {"%"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-start flex-wrap">
            <p style={TextStyle}>
              {auth.language === "en"
                ? "Provider Rating "
                : "ফ্রিল্যান্সার রেটিং"}
            </p>
            <div className="d-flex align-items-center justify-content-start">
              <p style={ValueStyle}>
                {noProvider
                  ? provider.provider_rating.toFixed(2)
                  : provider.provider_id?.provider_rating.toFixed(2)}{" "}
              </p>
              <img src="/assets/rater-start.png" alt="" className="" />
            </div>
          </div>
          {true && provider.provider_id?.location && (
            <p style={ValueStyle}>
              <img src="/assets/location.png" alt="" />{" "}
              {provider.provider_id?.location || "Location not available"}
            </p>
          )}
        </div>
      </div>
      <div>
        <p style={headingStyle}>
          {noProvider ? provider.fullname : provider.provider_id?.fullname}
        </p>
        {noProvider ? (
          <p className="mb-2" style={taglineStyle}>
            {provider?.tagline || "No tagline available"}
          </p>
        ) : (
          provider?.provider_id?.tagline && (
            <p className="mb-2" style={taglineStyle}>
              {provider?.provider_id?.tagline || "No tagline available"}
            </p>
          )
        )}
      </div>
      <Link to={`/profile/${provider.provider_id?._id}`}>
        <button style={buttonStyle}>
          <span style={buttonTextStyle}>
            {auth.language === "en" ? "View Profile" : "বৃত্তান্ত দেখুন"}
          </span>
        </button>
      </Link>
    </div>
  );
};

export default ProviderCardNew;
